<template>
  <section v-if="!!item" class="prompt-content">
    <div class="left">
      <div class="header">
        <div style="display: flex;">
          <h3>Prompt chain:</h3>
          <p-button :disabled="isPromptStreaming" @click="exportPrompt">
            <md-icon name="export" />
          </p-button>
          <p-button :disabled="isPromptStreaming" @click="importModalVisible = true">
            <md-icon name="import" />
          </p-button>
        </div>
        <div>
          <p-button v-if="$hasPermission('prompts.write') && hasChanges" variant="text" color="primary"
            :disabled="isUpdateRequestPending" @click="save">
            <md-icon name="content-save" />
          </p-button>
          <p-button :disabled="isPromptStreaming" @click="exec">
            <md-icon name="fast-forward" />
          </p-button>
        </div>
      </div>

      <div v-for="(promptItem, index) in chain" :key="index" class="accordion-item">
        <div class="accordion-item-header">
          <p-button @click="toggleExpand(index)">
            <md-icon :name="expandedPrompts.includes(index) ? 'chevron-up' : 'chevron-down'" /></p-button>
          <span v-if="editingPromptIndex !== index" @click="editingPromptIndex = index">
            {{ index + 1 }}. {{ promptItem.name || `PROMPT ${index + 1}` }}
          </span>
          <TextField v-else v-model="promptItem.name" :focus="true" @blur="editingPromptIndex = null" />
          <div>
            <p-button :variant="'text'" @click="deletePrompt(index)">
              <md-icon :name="'delete'" /></p-button>
            <p-button :variant="'text'" :disabled="isPromptStreaming" @click="execOne(index)">
              <md-icon :name="'play'" /></p-button>
          </div>
        </div>
        <div v-if="expandedPrompts.includes(index)" class="accordion-item-content">
          <PromptItem :item="promptItem" :index="index" :suggestions="suggestions" :models="models"
            @change="onChange($event, index)" />
        </div>
      </div>
      <p-button color="primary" @click="addPrompt">Add prompt</p-button>
    </div>
    <div id="response" class="right">
      <section class="sample-input-wrapper">
        <div>
          <div class="variables-header">
            <div>
              <h3 style="display: inline-block;">
                Variables
              </h3>
              <p-button v-if="variablesExpanded" variant="text" @click="toggleVariablesEdiding">
                <md-icon :name="isEditingVariablesList ? 'check' : 'pencil'" /></p-button>
            </div>
            <p-button variant="text" @click="variablesExpanded = !variablesExpanded">
              <md-icon :name="variablesExpanded ? 'chevron-up' : 'chevron-down'" />
            </p-button>
          </div>
          <template v-if="variablesExpanded">
            <div v-if="!isEditingVariablesList">
              <div v-for="(v, index) in variables" :key="v.name" class="variable-wrapper">
                <TextField v-model="variableValues[index]" :rows="1" :multiline="true" :resizable="true"
                  :label="v.label" />
              </div>
              <div v-for="(v, index) in chain" :key="v.name" class="variable-wrapper">
                <TextField v-model="promptResponses[index]" :rows="1" :multiline="true" :resizable="true"
                  :label="`PROMPT_${index + 1}_OUTPUT`" />
              </div>
            </div>
            <Variables v-else v-model="variables" />
          </template>
        </div>
      </section>
      <section class="sample-output-wrapper">
        <Output />
      </section>
    </div>
    <ImportPromptModal v-if="importModalVisible" @import="doImport" @cancel="importModalVisible = false" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Button from '@/components/common/Button';
import ImportPromptModal from './ImportPromptModal.vue';

import TextField from '@/components/common/TextField';
import Variables from './Variables';
import MdIcon from '@/components/common/MdIcon';
import PromptItem from './PromptItem.vue';
import Output from './Output.vue';

function deepCloneArray(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('Input is not an array');
  }

  return arr.map(item => {
    if (Array.isArray(item)) {
      return deepCloneArray(item);
    } else if (typeof item === 'object' && item !== null) {
      return deepCloneObject(item);
    } else {
      return item;
    }
  });
}

function deepCloneObject(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const clone = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        clone[key] = deepCloneArray(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        clone[key] = deepCloneObject(obj[key]);
      } else {
        clone[key] = obj[key];
      }
    }
  }

  return clone;
}

export default {
  components: {
    'p-button': Button,
    PromptItem,
    Output,
    TextField,
    Variables,
    MdIcon,
    ImportPromptModal
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chain: this.item.template.chain ? deepCloneArray(this.item.template.chain) : [],
      variables: this.item.template.variables || [],
      isReady: false,
      variableValues: this.item.template.variables ? this.item.template.variables.map(v => v.value) : [],
      isEditingVariablesList: false,
      expandedPrompts: [],
      promptResponses: [],
      variablesExpanded: true,
      editingPromptIndex: null,
      runningPromptIndex: null,
      multiResponse: true,
      importModalVisible: false
    };
  },
  computed: {
    ...mapState({
      isUpdateRequestPending: (s) => s.prompts.isUpdateRequestPending,
      isRequestPending: (s) => s.prompts.sample.isRequestPending,
      isRequestFailed: (s) => s.prompts.sample.isRequestFailed,
      models: (s) => s.prompts.sample.models.map(m => m.model),
      isPromptStreaming: (s) => s.prompts.sample.isRequestPending,
      response: (s) => s.prompts.sample.response,
    }),
    suggestions() {
      return this.variables.map(v => ({
        label: v.name,
        documentation: v.label
      }));
    },
    hasChanges() {
      return JSON.stringify(this.chain) !== JSON.stringify(this.item.template.chain) ||
        JSON.stringify(this.variables) !== JSON.stringify(this.item.template.variables);
    }
  },
  watch: {
    response() {
      this.$nextTick(() => {
        const objDiv = document.getElementById('response');

        objDiv.scrollTop = objDiv.scrollHeight;
      });
    },

    isPromptStreaming(nw) {
      if (nw) {
        return;
      }
      const filtered = this.response.filter(m => m.author !== "CURRENT_USER").map(m => m.text)
      if (this.multiResponse) {

        this.promptResponses = filtered;
      } else {
        this.promptResponses[this.runningPromptIndex] = filtered[0];
      }
    }

  },
  async created() {
    this.$store.dispatch('prompts/sample/reset');
    await this.$store.dispatch('prompts/sample/getModels');
  },
  methods: {
    async save() {
      try {
        await this.$emit('submit', {
          id: this.item.id,
          template: {
            model: this.model,
            max_tokens: this.max_tokens,
            temperature: this.temperature,
            variables: this.variables,
            chain: this.chain
          }
        });
        this.$toast.success({
          title: 'Update completed',
          message: `Prompt was updated.`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Update failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    doImport(templateString) {
      this.importModalVisible = false;
      try {
        const template = JSON.parse(templateString);
        this.chain = template.chain || [];
        this.variables = template.variables || [];
        this.variableValues = template.variables ? template.variables.map(v => v.value) : [];
        this.editingPromptIndex = null;
        this.runningPromptIndex = null;
      } catch (error) {
        this.$toast.error({
          title: 'Invalid import string'
        });
      }
    },
    async exec() {
      this.multiResponse = true;
      try {
        await this.$store.dispatch('prompts/sample/exec', {
          chain: this.chain,
          variables: this.variables.map((v, index) => {
            return {
              ...v,
              value: this.variableValues[index]
            };
          }),
          debug: true
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to generate sample',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async exportPrompt() {
      await navigator.clipboard.writeText(
        JSON.stringify({
          model: this.model,
          max_tokens: this.max_tokens,
          temperature: this.temperature,
          variables: this.variables,
          chain: this.chain
        })
      );
      this.$toast.success({
        message: `Template is copied to clipboard`
      });
    },
    execOne(index) {
      this.multiResponse = false;
      this.runningPromptIndex = index;
      const bindingRegex = /{(?<binding>.+?)}/gi;
      const allVariables = [
        ...this.variables.map((v, index) => {
          return {
            ...v,
            value: this.variableValues[index]
          };
        }),
        ...this.chain.map((c, index) => {
          return {
            name: `PROMPT_${index + 1}_OUTPUT`,
            value: this.promptResponses[index]
          }
        })
      ];

      const prepairedMessages = this.chain[index].messages.map(m => {
        let content = m.content;
        let match;
        while ((match = bindingRegex.exec(m.content)) !== null) {
          const variable = allVariables.find(v => v.name === match.groups.binding);
          if (variable) {
            content = content.replace(match[0], variable.value);
          }
        }
        return {
          ...m,
          content
        };
      });
      this.$store.dispatch('prompts/sample/execOne', { ...this.chain[index], messages: prepairedMessages, variables: allVariables });
    },
    toggleVariablesEdiding() {
      this.isEditingVariablesList = !this.isEditingVariablesList;
      if (!this.isEditingVariablesList) {
        this.variableValues = this.variables.map(v => v.value);
      }
    },
    onChange(item, index) {
      this.chain.splice(index, 1, { ...item });
    },
    toggleExpand(index) {
      if (this.expandedPrompts.includes(index)) {
        this.expandedPrompts = this.expandedPrompts.filter(i => i !== index);
      } else {
        this.expandedPrompts.push(index);
      }
    },
    addPrompt() {
      this.chain.push({
        max_tokens: null,
        name: `PROMPT_${this.chain.length + 1}`,
        model: this.models[0],
        messages: [
          {
            role: 'system',
            content: 'You are a patent attorney who always answers correctly.'
          },
          {
            role: 'user',
            content: 'Who are you?'
          },
        ],
        temperature: 0.7
      });
      this.expandedPrompts.push(this.chain.length - 1);
    },
    deletePrompt(index) {
      this.chain.splice(index, 1);
      this.expandedPrompts = this.expandedPrompts.filter(i => i !== index);
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.prompt-content {
  h3 {
    text-transform: uppercase;
    font-size: 0.85rem;
    font-weight: 500;
    align-self: center;
    padding: 0.05rem 0.5rem;
    margin: 15px 0;
  }

  width: 100%;
  height: 100%;
  margin: 0 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 0.5rem;

  .left {
    height: 100%;
    overflow-y: scroll;
    grid-gap: 20px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .accordion-item {
    &:last-of-type {
      margin-bottom: 20px;
    }

    .accordion-item-header {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      gap: 10px;
      background-color: var(--theme-surface);
      border-bottom: 1px solid var(--theme-on-surface);
      padding: 5px 0;
    }

    .accordion-item-content {
      padding: 10px;
    }
  }

  .right {
    height: 100%;
    position: relative;
    overflow-y: scroll;

    .variable-wrapper {
      margin-bottom: 15px;
    }

    .sample-output-wrapper {
      overflow: hidden;

      .button-wrapper {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .prompt-content-wrapper,
  .sample-input-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 0.5rem;

    .variables-header {
      display: flex;
      justify-content: space-between;
    }

  }

  .prompt-content-title-wrapper,
  .sample-input-title-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    justify-content: baseline;
    height: 20px;

    >div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }


}
</style>
