<template>
  <div>
    <div class="row">
      <h2 class="title">Title:</h2>
      <div v-for="(t, index) in title" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="title.length"
          :code="code"
          :source="source"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('title', index)"
          @add="addCondition('title')"
          @input="emitChange"
        >
          <p-input-variables
            v-model="t.value"
            :additional-options="simpleVariables"
            :source="source"
            :code="code"
            :placeholder="'Title of task created by this step'"
            @input="emitChange"
          />
        </with-predicates>
      </div>
    </div>
    <div class="row">
      <h2 class="title">Due at:</h2>
      <div v-for="(t, index) in dueAt" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="dueAt.length"
          :code="code"
          :source="source"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('dueAt', index)"
          @add="addCondition('dueAt')"
          @input="emitChange"
        >
          <p-wizard-due-at v-model="t.value" :source="source" :code="code" :additional-options="dateVariables" @input="emitChange" />
        </with-predicates>
      </div>
    </div>

    <div class="row">
      <h2 class="title">Assignees:</h2>
      <div v-for="(t, index) in assignees" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="assignees.length"
          :code="code"
          :source="source"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('assignees', index)"
          @add="addCondition('assignees')"
          @input="emitChange"
        >
          <p-assignees-with-variables
            v-model="t.value"
            :additional-options="peopleVariables"
            :exclude-contracts="excludedContracts"
            :source="source"
            :code="code"
            @input="emitChange"
          />
        </with-predicates>
      </div>
    </div>

    <div class="row">
      <h2 class="title">Attachments:</h2>
      <div v-for="(t, index) in attachments" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="attachments.length"
          :code="code"
          :source="source"
          :predicates="t.predicates"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('attachments', index)"
          @add="addCondition('attachments')"
          @input="emitChange"
        >
          <p-input-variables
            v-model="t.value"
            :additional-options="attachmentsVariables"
            :source="source"
            :code="code"
            :data-type="'file'"
            :exclude-contracts="['uspto', 'invention', 'milestone', 'email', ...excludedContracts]"
            :placeholder="'Files attached to this task'"
            @input="emitChange"
          />
        </with-predicates>
      </div>
    </div>

    <div class="row">
      <h2 class="title">Attached emails:</h2>
      <div v-for="(t, index) in emailAttachments" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="emailAttachments.length"
          :code="code"
          :source="source"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('emailAttachments', index)"
          @add="addCondition('emailAttachments')"
          @input="emitChange"
        >
          <div style="padding-bottom: 10px">
            <Multiselect v-model="t.value" :multiple="true" :options="emailVariables" @input="emitChange" />
          </div>
        </with-predicates>
      </div>
    </div>

    <div class="row">
      <h2 class="title">Notes:</h2>
      <div v-for="(t, index) in notes" :key="index">
        <with-predicates
          v-model="t.predicates"
          :index="index"
          :value-length="notes.length"
          :code="code"
          :source="source"
          :additional-predicate-variables="additionalPredicateVariables"
          @remove="removeCondition('notes', index)"
          @add="addCondition('notes')"
          @input="emitChange"
        >
          <p-input-variables
            v-model="t.value"
            :source="source"
            :multiline="true"
            :additional-options="simpleVariables"
            :code="code"
            @input="emitChange"
          />
        </with-predicates>
      </div>
    </div>
  </div>
</template>
<script>
const convertToConditionalArray = (v, field) => {
  if (Array.isArray(v)) {
    if (v.length) {
      if (v[0].predicates) {
        return v;
      } else {
        return [{ predicates: [], value: v }];
      }
    } else {
      return [{ predicates: [], value: v }];
    }
  } else {
    return [{ predicates: [], value: v }];
  }
};
import Multiselect from '@/components/common/Multiselect';
import InputWithVariables from './InputWithVariables.vue';
import AssigneesWithVariables from './AssigneesWithVariables.vue';
import DueDateWithVariables from './DueDateWithVariables.vue';
import { mapState } from 'vuex';
import WithPredicates from './WithPredicates.vue';

export default {
  components: {
    'p-wizard-due-at': DueDateWithVariables,
    'p-assignees-with-variables': AssigneesWithVariables,
    'p-input-variables': InputWithVariables,
    WithPredicates,
    Multiselect
  },

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: ''
    },
    excludedContracts: {
      type: Array,
      default: () => []
    },
    connections: {
      type: Object,
      default: () => ({})
    },
    additionalPredicateVariables: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      title: convertToConditionalArray(this.value.title),
      dueAt: convertToConditionalArray(this.value.dueAt),
      assignees: convertToConditionalArray(this.value.assignees),
      attachments: convertToConditionalArray(this.value.attachments),
      emailAttachments: convertToConditionalArray(this.value.emailAttachments),
      notes: convertToConditionalArray(this.value.notes)
    };
  },
  computed: {
    ...mapState({
      contracts: (s) => s.milestones.contracts,
      milestone: (s) => s.milestones.item
    }),
    currentFormFields() {
      if (this.value.form) {
        return this.value.form.properties;
      }
      return [];
    },
    peopleVariables() {
      const people = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'people') : [];
      const formPeople = this.currentFormFields.filter((v) => v.type === 'people').map((v) => `{{form.${v.property}}}`);
      return [
        '{{thisTask.assignees}}',
        '{{thisTask.assignees.assistants}}',
        '{{milestone.assignees}}',
        '{{milestone.assignees.assistants}}',
        ...people.map((d) => d.saveTo),
        ...formPeople
      ];
    },
    attachmentsVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'file') : [];
      const formFiles = this.currentFormFields.filter((v) => v.type === 'file').map((v) => `{{form.${v.property}}}`);
      const resp = {
        thisTask: ['{{thisTask.attachments}}']
      };
      if (files?.length) {
        resp['milestone.variables'] = files.map((d) => d.saveTo);
      }
      if (formFiles?.length) {
        resp['form'] = formFiles;
      }
      return resp;
    },
    simpleVariables() {
      const resp = {
        thisTask: ['{{thisTask.notes}}', '{{thisTask.title}}', '{{thisTask.dueAt}}', '{{thisTask.assignees}}']
      };
      const vars = this.milestone.variables ? this.milestone.variables : [];
      if (vars) {
        resp['milestone.variables'] = vars.map((d) => d.saveTo);
      }
      if (this.currentFormFields.length) {
        resp['form'] = this.currentFormFields.map((v) => `{{form.${v.property}}}`);
      }
      return resp;
    },
    dateVariables() {
      const dates = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'date') : [];

      const formDates = this.currentFormFields.filter((v) => v.type === 'date' || v.type === 'datetime').map((v) => `{{form.${v.property}}}`);
      return ['{{thisTask.dueAt}}', ...dates.map((d) => d.saveTo), ...formDates];
    },
    emailVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter((v) => v.type === 'email') : [];
      const formFiles = this.currentFormFields.filter((v) => v.type === 'email').map((v) => `{{form.${v.property}}}`);
      const resp = ['{{thisTask.emailAttachments}}'];

      if (files?.length) {
        resp.push(...files.map((d) => d.saveTo));
      }
      if (formFiles?.length) {
        resp.push(...formFiles);
      }
      if (this.connections.output.key === 'correspondence.recieved____output') {
        resp.push('{{email}}');
      }
      return resp;
    },
    source() {
      return this.connections.output.node.data.props.source;
    },
    code() {
      return this.connections.output.name;
    }
  },
  methods: {
    addCondition(field) {
      let value;
      if (field === 'attachments' || field === 'emailAttachments' || value === 'assignees') {
        value = [];
      }
      this[field].splice(this[field].length - 1, 0, { predicates: [], value });
    },
    removeCondition(field, index) {
      this[field].splice(index, 1);
    },
    emitChange() {
      this.$emit('input', {
        ...this.value,
        title: this.title,
        dueAt: this.dueAt,
        assignees: this.assignees,
        attachments: this.attachments,
        emailAttachments: this.emailAttachments,
        notes: this.notes
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  margin-bottom: 20px;
  border: 1px solid #fff;
  padding: 20px;
  .title {
    margin-bottom: 10px;
  }
}

.title {
  margin-bottom: 10px;
}
</style>
