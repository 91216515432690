import { cruduex, am, factory } from './utils';

import httpClient from '@/utils/httpClient';
import logger from '@/utils/logger';

const EXEC = am(`EXEC_SPECIFICATION`);
const INITIALIZE = am(`SAMPLE_INITIALIZE`);
const GET_MODELS = am(`GET_MODELS`);

const EXEC_PROGRESS = 'EXEC_PROGRESS';
const MESSAGE_ADDED = 'MESSAGE_ADDED';
const RETRY_OCCURED = 'RETRY_OCCURED';
const ERROR_OCCURED = 'ERROR_OCCURED';

export default {
  namespaced: true,
  // leave this here until further features are added
  ...factory({
    state: {},
    mutations: {},
    actions: {}
  })(
    'prompt',
    {
      async getById(id) {
        return httpClient.get(`/api/prompts/${id}`);
      },
      async getCollection() {
        return httpClient.get('/api/prompts/');
      },
      async create({ name, model, isPublic, description, template, tags }) {
        return httpClient.post('/api/prompts/', {
          name,
          model,
          isPublic,
          description,
          template,
          tags
        });
      },
      async update(id, args) {
        return httpClient.patch(`/api/prompts/${id}`, args);
      },
      async delete(id) {
        return httpClient.delete(`/api/prompts/${id}`);
      }
    },
    {
      sample: {
        namespaced: true,
        state: {
          isRequestPending: false,
          isRequestFailed: false,
          response: [],
          isLoadingModels: false,
          models: []
        },
        mutations: {
          [INITIALIZE.STARTED](state) {
            state.isRequestPending = false;
            state.isRequestFailed = false;
            state.response = [];
          },
          [EXEC.STARTED](state) {
            state.isRequestPending = true;
            state.isRequestFailed = false;
            state.response = [];
          },
          [EXEC_PROGRESS](state, response) {
            state.response[state.response.length - 1].text += response;
          },
          [MESSAGE_ADDED](state, msg) {
            const userMessage = msg.messages.find(m => m.role === 'user');
            const systemMessage = msg.messages.find(m => m.role === 'system');
            state.response.push({
              text: userMessage.content,
              author: 'CURRENT_USER'
            });
            state.response.push({
              text: '',
              author: msg.model,
              system: systemMessage.content,
              retry: false,
              error: false
            });
          },
          [RETRY_OCCURED](state, msg) {
            state.response[state.response.length - 1].retry = true;

            state.response[state.response.length - 1].text = 'An error occured. Retrying...';
            const systemMessage = msg.messages.find(m => m.role === 'system');
            state.response.push({
              text: '',
              author: msg.model,
              system: systemMessage.content,
              retry: false,
              error: false
            });
          },
          [ERROR_OCCURED](state, msg) {
            state.response[state.response.length - 1].text = msg;
            state.response[state.response.length - 1].error = true;
          },
          [EXEC.COMPLETED](state, response) {
            state.isRequestPending = false;
          },
          [EXEC.FAILED](state, response) {
            state.isRequestPending = false;
            state.isRequestFailed = true;
            state.response = [];
          },
          [GET_MODELS.STARTED](state) {
            state.isLoadingModels = true;
          },
          [GET_MODELS.COMPLETED](state, models) {
            state.isLoadingModels = false;
            state.models = models;
          },
          [GET_MODELS.FAILED](state) {
            state.isLoadingModels = false;
          }
        },
        actions: {
          async exec({ commit }, prompt) {
            try {
              commit(EXEC.STARTED);

              httpClient.stream(
                `/api/nlp/api/v1/ai/chains`,
                { body: { ...prompt, debug: true } },
                function(e) {
                  commit(MESSAGE_ADDED, e);
                },
                function(chunk) {
                  commit(EXEC_PROGRESS, chunk);
                },
                function() {
                  commit(EXEC.COMPLETED);
                },
                function(e) {
                  commit(RETRY_OCCURED, e);
                },
                function(e) {
                  commit(ERROR_OCCURED, e);
                }
              );
            } catch (e) {
              logger.error(e);

              try {
                const responseText = await e.response.text();
                const { message } = JSON.parse(responseText);
                commit(EXEC.FAILED, message);
              } catch (e) {
                commit(EXEC.FAILED, 'Unable to generate an output.');
              }
            }
          },
          async execOne({ commit }, prompt) {
            commit(EXEC.STARTED);

            try {
              commit(MESSAGE_ADDED, prompt);
              const response = await httpClient.post(`/api/nlp/api/v1/ai`, { ...prompt });
              commit(EXEC_PROGRESS, response);
              commit(EXEC.COMPLETED);
            } catch (e) {
              commit(EXEC.FAILED, 'Unable to generate an output.');
            }
          },
          reset({ commit }) {
            commit(INITIALIZE.STARTED);
          },
          async getModels({ commit }) {
            try {
              commit(GET_MODELS.STARTED);
              const models = await httpClient.get('/api/nlp/api/v1/ai/chains');
              commit(GET_MODELS.COMPLETED, models);
            } catch (e) {
              commit(GET_MODELS.FAILED, e);
              throw e;
            }
          }
        }
      }
    }
  )
};
