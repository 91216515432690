<template>
  <div class="prompt-item">
    <div class="settings">
      <Multiselect v-model="localItem.model" label="Model" :options="models" @input="onChange" />
      <TextField v-model="localItem.temperature" label="Temperature" type="number" @input="onChange" />
      <TextField v-model="localItem.max_tokens" label="Max tokens" type="number" @input="onChange" />
    </div>
    <div class="content">
      <div v-for="(message, messageIndex) in localItem.messages" :key="messageIndex" class="template-content">
        <div class="head">
          <span class="label">{{ message.role }}:</span>
          <Button v-if="messageIndex === localItem.messages.length - 1 && localItem.messages.length > 2" color="error"
            @click="deleteMessage">
            <MdIcon size="sm" name="delete" />
          </Button>
        </div>
        <p-code v-model="message.content" :read-only="!$hasPermission('prompts.write')" :language="'plaintext'"
          :suggestions="[...suggestions, ...outputVariables]" class="editor" :auto-height="true" @input="onChange" />
      </div>
      <Button color="primary" @click="addMessage">Add message</Button>
    </div>
  </div>
</template>

<script>
import Code from '@/components/common/Code';
import Button from '@/components/common/Button';
import Multiselect from '@/components/common/Multiselect';
import TextField from '@/components/common/TextField';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    'p-code': Code,
    Multiselect,
    TextField,
    Button,
    MdIcon
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    models: {
      type: Array,
      default: () => []
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      localItem: { ...this.item }
    };
  },
  computed: {
    outputVariables() {
      if (this.index === 0) {
        return [];
      }

      return new Array(this.index).fill('a').map((a, index) => ({
        label: `PROMPT_${index + 1}_OUTPUT`,
        documentation: `Output of prompt ${index + 1}`
      }))
    }
  },
  methods: {
    onChange() {
      this.$emit('change', { ...this.localItem });
    },
    addMessage() {
      this.localItem.messages.push({
        role: this.localItem.messages.length % 2 === 1 ? 'user' : 'assistant',
        content: ''
      });
      this.onChange();
    },
    deleteMessage() {
      this.localItem.messages.pop();
      this.onChange();
    }
  }
}
</script>

<style scoped lang="scss">
.prompt-item {

  .settings {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .content {
    padding: 10px;

    .template-content {
      margin: 10px 0;
      display: grid;
      gap: 10px;

      .label {
        font-weight: 500;
        font-size: 0.75rem;
        letter-spacing: 0.025em;
      }

      .editor {
        height: 200px;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
