<template>
  <div class="content">
    <div v-for="(message, index) in response" :key="index" :class="message.author === 'CURRENT_USER' ? 'user' : 'model'"
      class="message">
      <span class="label" :title="message.system">
        {{ message.author === 'CURRENT_USER' ? email : message.author }}
        <MdIcon v-if="message.author !== 'CURRENT_USER'" name="help" size="sm" />
        <MdIcon v-if="message.retry" title="retry" name="alert-circle-outline" color="warning" size="sm" />
        <MdIcon v-if="message.error" title="error" name="alert-circle-outline" color="error" size="sm" />
      </span>
      <div>
        <span class="body" :class="{ retry: message.retry }">
          {{ message.text }}
          <template v-if="isRequestPending && index === response.length - 1">
            <div class="typing">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </template>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    MdIcon,
  },
  computed: {
    ...mapState({
      response: (s) => s.prompts.sample.response,
      email: s => s.identity.email,
      isRequestPending: (s) => s.prompts.sample.isRequestPending,
    }),
  }
}
</script>

<style scoped lang="scss">
.content {
  overflow-y: scroll;
  white-space: pre-line;
  height: 90%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 12px;

  .message {
    padding: 0.75rem 1rem 0.75rem 1.25rem;
    padding-right: 25%;

    $dot-width: 5px;
    $dot-color: var(--theme-primary);
    $speed: 1.5s;

    .typing {
      position: relative;

      span {
        content: '';
        animation: blink $speed infinite;
        animation-fill-mode: both;
        height: $dot-width;
        width: $dot-width;
        background: $dot-color;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;

        &:nth-child(2) {
          animation-delay: .2s;
          margin-left: $dot-width * 1.5;
        }

        &:nth-child(3) {
          animation-delay: .4s;
          margin-left: $dot-width * 3;
        }
      }
    }


    @keyframes blink {
      0% {
        opacity: .1;
      }

      20% {
        opacity: 1;
      }

      100% {
        opacity: .1;
      }
    }

    &.user {
      text-align: right;
      padding-left: 25%;
      padding-right: 5px;
    }

    .label {
      font-size: 0.7rem;
      font-style: italic;
      color: var(--theme-on-background-accent);
    }

    .body {
      display: inline-block;
      background-color: var(--theme-surface);
      padding: 9px;
      border-radius: 6px;
      text-align: left;
      line-height: 1.3rem;

      &.retry {
        font-style: italic;
      }
    }
  }

}
</style>
