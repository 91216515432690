<template>
  <section v-if="isGetRequestPending">
    <p-loading />
  </section>
  <section v-else-if="item.name" class="prompts">
    <header>
      <div class="prompt-name-wrapper">
        <Button @click="$router.push({ path: '/prompts' })">
          <MdIcon name="arrow-left" />
        </Button>
        Prompt:
        <label class="prompt-name">{{ item && item.name }}</label>
      </div>
    </header>
    <PromptContent :item="item" @submit="save" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import Loading from '@/components/common/Loading';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

import PromptContent from './PromptContent.vue';
export default {
  components: {
    'p-loading': Loading,
    PromptContent,
    Button,
    MdIcon
  },
  computed: {
    ...mapState({
      item: (s) => s.prompts.item,
      isGetRequestPending: (s) => s.prompts.isGetRequestPending
    })
  },
  watch: {
    '$route.params'() {
      this.initialize();
    }
  },
  async created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      await this.$store.dispatch('prompts/getById', this.$route.params.id);
    },
    selectTemplate(id) {
      this.$router.push({ path: `/prompts/${id}` });
    },
    async save({ id, template }) {
      await this.$store.dispatch('prompts/update', {
        id,
        template
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.prompts-wrapper {
  width: 100%;
  height: 100%;
}

.prompts {
  width: 100%;
  margin: 0 0.5rem;
  overflow: hidden;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr);
  grid-gap: 0.5rem;

  >header {
    grid-column: 1/3;
    background: var(--theme-surface);
    padding: 0.75rem 2rem;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .prompt-name-wrapper {
      cursor: pointer;

      >* {
        cursor: pointer;
      }
    }

    .prompt-name {
      text-transform: uppercase;
      font-weight: 500;
      color: var(--theme-on-background-dark);
      padding: 0 5px;
    }
  }
}
</style>
