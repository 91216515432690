<template>
  <div>
    <div v-for="(variable, index) in localVariables" :key="index" class="row">
      <TextField v-model="variable.name" label="Name" @input="$emit('input', localVariables)" />
      <TextField v-model="variable.label" label="Label" @input="$emit('input', localVariables)" />
      <TextField v-model="variable.value" label="Default value" @input="$emit('input', localVariables)" />
      <Multiselect v-model="variable.type" label="Type" :options="['string']" @input="$emit('input', localVariables)" />
      <Button @click="removeVariable(index)">
        <MdIcon name="close" />
      </Button>
    </div>
    <div>
      <Button color="primary" @click="addVariable">Add variable</Button>
    </div>
  </div>
</template>

<script>
import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    TextField,
    Multiselect,
    Button,
    MdIcon
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      localVariables: this.value.map((v) => ({ ...v })),
      isInEditMode: false
    };
  },
  methods: {
    addVariable() {
      this.localVariables.push({
        name: '',
        label: '',
        type: 'string'
      });
    },
    removeVariable(index) {
      this.localVariables.splice(index, 1);
      this.$emit('input', this.localVariables);
    }
  }
}
</script>

<style scoped>
/* Add your styles here */
.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr max-content;
  margin-bottom: 15px;
  grid-gap: 10px;
  align-items: center;
}
</style>
