<template>
  <section v-if="!isReady">
    <p-loading />
  </section>
  <section v-else class="fip">
    <header>
      <h1>Fip dashboard</h1>
    </header>
    <section class="fip-list-wrapper">
      <p-fip-inspect-modal v-if="inspectModalVisible" :error="itemToInspect && itemToInspect.error"
        @close="inspectModalVisible = false" />
      <div class="header-wrapper">
        <div class="filter">
          <p-checkbox v-model="filter.synchronized" label="Synchronized" @change="onFilterChange"></p-checkbox>
          <p-checkbox v-model="filter.failed" label="Failed" @change="onFilterChange"></p-checkbox>
        </div>
        <div>Sort by:<p-dropdown :value="sort" :options="options.sorting" @change="setSort"></p-dropdown></div>
        <div><label>Total:</label>&nbsp;{{ total }}</div>
      </div>
      <ul class="fip-list">
        <li class="fip-list-item header">
          <div>Matter #</div>
          <div>FIPID:</div>
          <div>Sync status</div>
          <div>Created At</div>
          <div>Updated At</div>
          <div>Error message</div>
        </li>
      </ul>
      <ul class="fip-list">
        <li v-for="entry in collection" :key="entry.id" class="fip-list-item">
          <div>
            <span>{{ entry.matterNumber }}</span>
          </div>
          <div>
            {{ entry.fipId }}
          </div>
          <div :style="{ color: SYNC_STATUS[entry.synchronized === false ? 'failed' :'synchronized'].color }">
            {{ SYNC_STATUS[entry.synchronized === false ? 'failed' :'synchronized'].label }}
          </div>
          <div>{{ entry.createdAt && new Date(entry.createdAt).toLocaleString() }}</div>
          <div>
            {{ entry.updatedAt && new Date(entry.updatedAt).toLocaleString() }}
          </div>
          <div>
            <div class="fip-list-item-error">{{ entry.error }}</div>
            <div class="fip-list-item-actions">
              <p-button v-if="(!entry.synchronized && entry.error)" variant="text" color="secondary"
                title="Inspect this application" @click="inspect(entry)">≡</p-button>
              <p-button variant="text" title="Sync matter"
                @click="sync(entry)">&#8634;</p-button>
            </div>
          </div>
        </li>
      </ul>
      <Pagination :total="total" :size="20" :from="from" @navigate="onNavigate" />
    </section>
    <section class="chart-wrapper">
      <header>
        <h2>Summary</h2>
      </header>
      <p-pie-chart v-if="!!aggs && Object.keys(aggs).length" :key="aggsHash" :aggs="aggs" />
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { lw } from '@/utils/lw';
import Loading from '@/components/common/Loading';
import Checkbox from '@/components/common/Checkbox';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import PieChart from './PieChart.js';
import InspectModal from './InspectModal';
import { SYNC_STATUS } from './fip_constants';
import Pagination from '@/components/common/Pagination.vue';
const LOCAL_STORAGE_FIP_STATUS_LIST = 'LOCAL_STORAGE_FIP_STATUS_LIST';
const LOCAL_STORAGE_FIP_SORT_KEY = 'LOCAL_STORAGE_FIP_SORT_KEY';

const STATUS_LIST = Object.keys(SYNC_STATUS);

export default {
  components: {
    'p-loading': Loading,
    'p-button': Button,
    'p-dropdown': Dropdown,
    'p-checkbox': Checkbox,
    'p-pie-chart': PieChart,
    'p-fip-inspect-modal': InspectModal,
    Pagination
  },
  data() {
    const statuses = lw.get(LOCAL_STORAGE_FIP_STATUS_LIST) || ['synchronized', 'failed'];

    return {
      sort: lw.get(LOCAL_STORAGE_FIP_SORT_KEY) || '',
      filter: STATUS_LIST.reduce(
        (obj, key) => ({
          ...obj,
          [key]: statuses.includes(key)
        }),
        {}
      ),
      options: {
        sorting: [
          { id: 'asc', label: 'Asc' },
          { id: 'desc', label: 'Desc' }
        ]
      },
      autorefreshInterval: null,
      inspectModalVisible: false,
      itemToInspect: null,
      from: 0,
      SYNC_STATUS
    };
  },
  computed: {
    ...mapState({
      total: s => s.fip.total,
      collection: s => s.fip.collection,
      aggs: s => s.fip.aggs,
      // prettier-ignore
      aggsHash: s => `${s.fip.aggs.synchronized || '0'}${s.fip.aggs.failed || '0'}`,
      isReady: s => s.fip.isReady,
      active: s => s.fip.active
    }),
  },
  watch: {
    filter: {
      handler() {
        lw.set(
          LOCAL_STORAGE_FIP_STATUS_LIST,
          Object.keys(this.filter).filter(key => !!this.filter[key])
        );
      },
      deep: true
    }
  },
  created() {
    this.autorefreshInterval = 60 * 1000;
    this.autorefresh();
  },
  destroyed() {
    this.autorefreshInterval = null;
  },
  methods: {
    async sync(matter) {
      try {
        await this.$store.dispatch('fip/sync', matter.fipId);

        this.$toast.success({
          title: 'Sync completed',
          message: `Sync for ${matter.fipId} (${matter.matterNumber}) is completed.`
        });

        this.loadData();
      } catch (e) {
        this.$toast.error({
          title: 'Sync failed',
          message: `Sync for ${matter.fipId} (${matter.matterNumber}) is failed.`
        });
      }
    },
    setSort(id) {
      const option = this.options.sorting.find(option => option.id === id);
      if (!option) {
        return;
      }
      lw.set(LOCAL_STORAGE_FIP_SORT_KEY, id);

      this.sort = id;
      this.skip = 0;

      this.loadData();
    },
    async autorefresh() {
      while (this.autorefreshInterval) {
        try {
          await this.loadData();
        } catch (e) {
          this.autorefreshInterval = null;
        }
        await new Promise(resolve => setTimeout(resolve, this.autorefreshInterval));
      }
    },
    inspect(item) {
      this.inspectModalVisible = true;
      this.itemToInspect = item;
    },
    loadData() {
      this.$store.dispatch('fip/getCollection', {
        skip: this.from,
        limit: 20,
        status: Object.keys(this.filter).filter(key => !!this.filter[key]),
        sort: this.sort
      });
    },
    onNavigate({ from }) {
      this.from = from;
      this.loadData()
    },
    onFilterChange() {
      this.from = 0;
      this.loadData();
    }
  }
};
</script>

<style lang="scss" scoped>
.fip {
  display: grid;
  padding: 0 0.5rem 0 1rem;
  grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 0.5rem;
  width: 100%;
  height: 100%;

  >header {
    grid-column: 1/2;
    grid-row: 1/2;
    padding: 0.5rem 0.5rem 0;
    display: flex;

    box-sizing: border-box;

    h1 {
      color: var(--theme-on-surface);
      font-size: 1.5rem;
      font-weight: 700;
      margin-right: 0.25rem;
    }

    .status-label {
      display: inline-flex;
      line-height: 1;
      height: 1rem;
      justify-content: center;
      align-items: center;
      border-radius: 10%;
      padding: 0.1rem 0.25rem;
      font-size: 0.75rem;
      font-weight: 700;

      &.healthy {
        color: var(--theme-on-success);
        background-color: var(--theme-success);
      }

      &.unhealthy {
        color: var(--theme-on-error);
        background-color: var(--theme-error);
      }
    }
  }

  .action-wrapper {
    grid-column: 2/3;
    grid-row: 1/2;

    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .fip-list-wrapper,
  .schedule-wrapper,
  .chart-wrapper {
    background-color: var(--theme-surface);
    padding: 1rem;
    position: relative;

    h2 {
      color: var(--theme-on-surface);
      font-size: 0.8rem;
      font-weight: 500;
      padding: 0;
      margin: 0;
      text-transform: uppercase;
    }
  }

  .fip-list-wrapper {
    display: grid;
    grid-template-rows: 20px max-content max-content minmax(0, 1fr) max-content;

    grid-column: 1/2;
    grid-row: 2/4;

    .header-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 6px;
      padding-bottom: 1rem;

      label {
        font-weight: 500;
      }

      >* {
        height: 100%;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-right: 2rem;
        }
      }

      .filter {
        >* {
          &:not(:last-child) {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .fip-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .fip-list-item {
      display: grid;
      grid-template-columns: 130px 175px 100px 175px 175px minmax(0, 1fr) 100px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;
      height: 1.75rem;
      align-items: center;

      >div {
        border-left: 1px solid var(--theme-highlight);
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          border-left: 1px solid var(--theme-highlight);
          border-right: 1px solid var(--theme-highlight);
        }

        >span {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight) ;
      }

      &:hover {
        background-color: var(--theme-highlight);

        .fip-list-item-actions {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);

        >* {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 0.5rem;
        }
      }

      .fip-list-item-error {
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .fip-list-item-actions {
        display: none;
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }

  .chart-wrapper,
  .schedule-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 0.5rem;
    position: relative;
  }

  .chart-wrapper {
    grid-row: 2/3;
    grid-column: 2/3;

    >* {
      min-width: 0;
      overflow: hidden;
    }
  }
}
</style>
